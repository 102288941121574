<template>
  <div class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>业务中台</el-breadcrumb-item>
           <el-breadcrumb-item>商城管理</el-breadcrumb-item>
           <el-breadcrumb-item>自提点设置</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
     <el-row class="content-box">
        <!-- 表头 -->
        <el-row>
          <h1 class="title">自提点列表</h1>
        </el-row>

        <!-- 主体内容 -->
        <div class="card-box">

          <div class="card" v-for="(item,index) in list" :key="index">
            <div class="card-top">
              <div class="card-left">
                <img src="../../../../assets/icon/adresslogo.png">
              </div>
              <div class="card-right">
                <p>
                  <el-tooltip :content="item.name" placement="top-start" effect="dark" popper-class="atooltip">
                    <span class="name">{{item.name}}</span>
                  </el-tooltip>
                  <span class="state" v-if="item.isEnable == 'NO'">停用</span>

                </p>
                <el-tooltip :content="item.place" placement="top-start" effect="dark" popper-class="atooltip">
                  <p>{{item.place}}</p>
                </el-tooltip>
                <p>{{item.phone}}</p>
              </div>
            </div>
            <div class="card-bottom">
              <el-button  type="text" @click="editadress(item.id,'删除')">删除</el-button> |
              <el-button  type="text" v-if="item.isEnable == 'NO'"  @click="editadress(item.id,'启用',item.isEnable)">启用</el-button>
              <el-button  type="text" v-if="item.isEnable == 'YES'" @click="editadress(item.id,'停用',item.isEnable)">停用</el-button> |
              <el-button  type="text" @click="saveadress(item)">编辑</el-button>
            </div>
          </div>

          <div class="add-card">
            <div class="add-btn" @click="saveadress()">+</div>
            <div>添加新自提点</div>
          </div>
        </div>
    <!--  新增/编辑  -->
        <el-dialog  :visible.sync="dialogFormVisible" @closed="closed" width="550px">
          <div slot="title" class="header-title">
            <span  class="title-name">{{addbtntype}}提示</span>
          </div>
          <el-form class="fotm-dialog-box" :model="form" ref="form"  :rules="rules">

            <el-form-item label="自提点名称" prop="name" :label-width="formLabelWidth">
              <el-input class="width-300" v-model="form.name" ></el-input>
            </el-form-item>

            <el-form-item label="自提点电话" prop="phone" :label-width="formLabelWidth">
              <el-input class="width-300" v-model="form.phone" ></el-input>
            </el-form-item>

            <el-form-item label="自提点地址" prop="place" :label-width="formLabelWidth">
              <el-input class="width-300" v-model="form.place" ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="Submitformdata">确 定</el-button>
          </div>
        </el-dialog>
    <!--删除/停用提示-->
        <el-dialog
            :visible.sync="centerDialogVisible"
            width="30%"
            @closed="closed"
            center>
          <div slot="title" class="header-title">
              <span  class="title-name">{{editbtntype}}提示</span>
              </div>
              <p class="tips" > 确定要{{ editbtntype }}该自提点吗</p>
              <div class="btn-box">
            <el-button @click="centerDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="editadresslist">确 定</el-button>
          </div>
        </el-dialog>
     </el-row>

  </div>

</template>

<script>
import { urlObj } from '@/api/interface'
import { mapState } from 'vuex'
export default {
  name: 'adressList',
  data(){
    return{
      page:1,
      limit:20,
      hotelId:'',
      total:0,
      addbtntype:'',//保存弹窗 标题
      editbtntype:'',//修改弹窗 标题
      isEnable:'',//地址状态标识
      isDelete:'',
      id:'',
      formLabelWidth:'100px',
      form:{
        name:'',
        place:'',
        phone:''
      },
      centerDialogVisible:false,
      dialogFormVisible:false,
      list:[],
      //校验
      rules: {
        name: [
          {required: true, message: '请输入自提点名称', trigger: 'blur'},
        ],
        place: [
          {required: true, message: '请输入自提点地址', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入自提点电话', trigger: 'blur'},
        ],
      }
    }
  },
  computed: {
    ...mapState(['dictData','hotelInfo'])
  },

  watch: {
    hotelInfo: {
      handler(newVal, oldVal) {
        if(newVal.id !== oldVal.id) {
          this.hotelId = this.hotelInfo.id
          this.getadresslists()
        }
      },
    }
  },
  mounted() {
    this.hotelId = this.hotelId = this.hotelInfo.id
    this.getadresslists()
  },
  methods: {
    // 获取订单列表
    getadresslists(){
      const url = urlObj.getadresslist + `?page=${this.page}&limit=${this.limit}`
      const param = {
        hotelId: this.hotelId,
      }
      this.$axios.post(url, param, 'json').then(async res => {
        if (res.success){
          this.total = res.total
          this.list = res.records

        }
      }).catch(error => {
        this.$message.error(error)
      })
    },
    //保存自提点
    Submitformdata(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const url = urlObj.addadressdata
          const param = {
            hotelId: this.hotelId,
            name:this.form.name,
            phone:this.form.phone,
            place:this.form.place,
            id:this.id,
          }
          console.log(param)
          this.$axios.post(url, param).then(async res => {
            if (res.success){
              this.$message({
                message: '保存成功',
                type: 'success'
              });

              this.getadresslists()
              this.dialogFormVisible = false
            }
          }).catch(error => {
            this.$message.error(error)
          })

        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    //删除和停用
    editadresslist(){
      const url = urlObj.addadressdata
      const param = {
        hotelId: this.hotelId,
        id:this.id,
        isDelete:this.isDelete,
        isEnable:this.isEnable
      }
      this.$axios.post(url, param).then(async res => {
        if (res.success){
          this.$message({
            message: '操作成功',
            type: 'success'
          });

          this.getadresslists()
          this.centerDialogVisible = false
        }
      }).catch(error => {
        this.$message.error(error)
      })
    },
    //弹窗关闭后 清空表单
    closed(){
      this.id = ''
      this.isDelete = ''
      this.isEnable = ''
      this.$refs['form'].resetFields()
    },
    // 删除/停用 弹窗标题
    editadress(id,type,adresstype){
        this.editbtntype = type
        this.id = id
        if (type == '删除'){
          this.isDelete = 'YES'
        }else{
          if (adresstype == 'NO'){
            this.isEnable = 'YES'
          }else{
            this.isEnable = 'NO'
          }
        }

      this.centerDialogVisible = true

    },
    //保存/修改 弹窗标题
    saveadress(val){

      if (val == undefined){
        this.addbtntype = '新增'
      }else{
        this.addbtntype = '编辑'
        this.form.phone = val.phone
        this.form.name = val.name
        this.form.place = val.place
        this.id = val.id
      }
      this.dialogFormVisible = true
    }
  },
}
</script>
<style scoped>
.cont{
  min-width: 1350px;
}
.title{
  margin: 10px 0;
  font-size: 18px;
  font-weight: 900;
}
.card-box{
  margin: 30px 85px;
  display: flex;
  flex-wrap:wrap;
}
.card{
  width: 30%;
  border: 1px solid #dedede;
  margin: 1%;
  position: relative;
  min-width: 370px;
}
.card-top{
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #dedede;
  background: #fff;
  height: 120px;
}
.card-left img{
  width: 100px;
}
.card-right{
  margin-left: 20px;
  font-size: 18px;
}
.card-right :nth-child(2){
  margin-top: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.name{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 150px;
}
.state{
  position: absolute;
  right: 20px;
  color: red;
  top: 0px;
}
.card-bottom button{
  width: 29%;
}
.add-card{
  width: 30%;
  border: 1px solid #dedede;
  margin: 1%;
  position: relative;
  text-align: center;
  opacity: 0.5;
  height: 200px;
}
.add-btn{
  font-size: 90px;
  cursor: pointer;
}
.tips{
  margin: 85px auto;
  text-align: center;
  font-size: 20px;
}
.btn-box{
  text-align: center;
}
.title-name{
  font-size: 24px;
}
</style>
